/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.padding {
  padding: 16px;
}

.line-breaker {
  white-space: pre-wrap;
}

.open-sans {
  font-family: "Open Sans Condensed", sans-serif !important;
}

.focus-outline :focus {
  outline: none !important;
}

.container {
  display: flex;
  flex: 1;
  min-height: 100%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.logo {
  height: 60px;
}

.custom-button {
  width: 100%;
  height: 46px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}
